import { Component } from '@angular/core';
import { ThemeCommonService } from './services/common.service';
import { BaThemeSpinner } from './services/leader.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { LocationService } from './services/location.service';
import { HttpService } from './services/http.service';
import { environment } from 'src/environments/environment';
import { SocketService } from './services/socket.service';
import { Subscription } from 'rxjs';
declare var $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private reassignSocket: Subscription;
  public reassignBookingId = '';
  public reassignMessage = '';
  constructor(
    private router: Router,
    public baThemeSpinner: BaThemeSpinner,
    public themeCommonService: ThemeCommonService,
    public locationService: LocationService,
    public httpService: HttpService,
    public socketService: SocketService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.baThemeSpinner.show();
      } else if (event instanceof NavigationEnd) {
        this.baThemeSpinner.hide();
        $('html, body').animate({ scrollTop: 0 }, { duration: 500 });
      }
    });
    this.addStripeKey();
    if (environment.production)
      this.twakToChat();
    //   this.wildix();

    this.socketService.setupSocketConnection();

    if (!localStorage.getItem('customBrowserId'))
      localStorage.setItem('customBrowserId', this.generateRandomStringAndNumbers());


    this.reassignSocket = this.socketService.reassignBooking.subscribe((object) => {
      if (object.message) {
        $('#acceptMerchant').modal('show');
        this.reassignBookingId = object.extraData.booking_id;
        this.reassignMessage = object.message;
      }
    });
    // setTimeout(() => {
    //   $("#alertMiss").modal('show');
    // }, 5000);

  }

  generateRandomStringAndNumbers() {
    let text = "";
    let possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 12; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  };

  private addStripeKey() {
    setTimeout(() => {
      if (window['Stripe']) {
        window['Stripe'].setPublishableKey(environment.stripeKey);
      } else {
        this.addStripeKey();
      }
    }, 4000);
  }

  private wildix() {
    (function (e) {
      window['kiteConfig'] = {
        "serialOrPbxName": "phlebotomyservices",
        "extension": "8",
        "language": "",
        "autoConnect": true,
        "autoLogin": true,
        "autoLoginName": "John Doe",
        "autoLoginEmail": "john-doe@email.com",
        "askGeolocation": true,
        "askNotification": true,
        "isExpanded": false,
        "expandTimeout": "",
        "openInNewWindow": false,
        "position": "bottom",
        "newDesign": true,
        "hoverTitle": "Your Health Pro",
        "buttonIcon": "chat",
        "buttonIconColor": "#003e69",
        "bottomOffset": "25px",
        "rightOffset": "25px"
      };
      var t = "kite.wildix.com"; var n = e.getElementsByTagName("script")[0]; var r = e.createElement("script");
      r.async = true; r.setAttribute("charset", "utf-8"); r.src = "https://" + t + "/" + window['kiteConfig'].serialOrPbxName + "/" + window['kiteConfig'].extension + "/widget?autocall";
      r.type = "text/javascript"; var i = e.createElement("link"); i.rel = "stylesheet"; i.type = "text/css";
      i.href = "https://" + t + "/static/css/widget.css"; n.parentNode.insertBefore(r, n); n.parentNode.insertBefore(i, n)
    })(document)
  }

  ngOnDestroy() {
    this.reassignSocket.unsubscribe();
  }

  assignMerchant(response?) {
    this.baThemeSpinner.show();
    let object = {
      access_token: localStorage.getItem('tokan'),
      booking_id: this.reassignBookingId,
      response: response
    }

    this.httpService.postData('/booking/response_reassign_request', object).subscribe(res => {
      this.baThemeSpinner.hide();
      if (res.status === 200) {
        $('#acceptMerchant').modal('hide');
        this.socketService.reassignBooking.next({});
      }
    }, () => {
      this.baThemeSpinner.hide();
    });
  }

  twakToChat() {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/61e610acb84f7301d32b87a5/1fplb295p';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }
}
