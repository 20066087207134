import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpService } from './http.service';
import { BaThemeSpinner } from './leader.service';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class LocationService {

  public broadcastLocations = new Subject<any>();
  public changeLocation = new Subject<any>();

  public changeInSavedAddress = new Subject<any>();

  constructor(
    public httpService: HttpService,
    public baThemeSpinner: BaThemeSpinner
  ) {
    this.getLocations();
  }

  getLocations() {

  }
}
