import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './auth-guard/auth-guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./components/home-new/home.module').then(m => m.HomeNewModule), canActivate: [AdminGuard] },
  // { path: 'home', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule) },
  { path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginRegisterModule), canActivate: [AdminGuard] },
  { path: 'register', loadChildren: () => import('./components/registration/registration.module').then(m => m.RegistrationModule), canActivate: [AdminGuard] },
  { path: 'verify-email/:tokan', loadChildren: () => import('./components/email-verify/email-verify.module').then(m => m.EmailVerifyModule) },
  { path: 'complete-registration/:tokan', loadChildren: () => import('./components/complete-profile/complete-profile.module').then(m => m.CompleteProfileModule) },
  { path: 'forgot-password', loadChildren: () => import('./components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule), canActivate: [AdminGuard] },
  { path: 'reset-password/:code/:user_id/:user_type', loadChildren: () => import('./components/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'terms', loadChildren: () => import('./components/terms/terms.module').then(m => m.TermsModule) },
  { path: 'privacy', loadChildren: () => import('./components/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'otp-verification/:user_id/:type/:mobile_number', loadChildren: () => import('./components/otp-verification/otp-verification.module').then(m => m.OtpVerificationModule) },
  { path: 'profile', loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'checkout', loadChildren: () => import('./components/checkout-page/checkout-page.module').then(m => m.CheckoutPageModule) },
  { path: 'merchant-listing', loadChildren: () => import('./components/merchant-listing/merchant-listing.module').then(m => m.MerchantListingNewModule) },
  { path: 'merchant/:merchant_id/:appointment_type', loadChildren: () => import('./components/merchant-profile/merchant-profile.module').then(m => m.MerchantProfileModule) },
  { path: 'merchant/:merchant_id', loadChildren: () => import('./components/merchant-profile/merchant-profile.module').then(m => m.MerchantProfileModule) },
  // { path: 'merchant-listing', loadChildren: () => import('./components/home-search/home-search.module').then(m => m.HomeSearchModule) },
  { path: 'wallet/:isCheckout', loadChildren: () => import('./components/wallet/wallet.module').then(m => m.WalletModule) },
  { path: 'wallet', loadChildren: () => import('./components/wallet/wallet.module').then(m => m.WalletModule) },
  { path: 'new-section', loadChildren: () => import('./components/new-section/new-section.module').then(m => m.NewSectionModule) },
  { path: 'merchant', loadChildren: () => import('./components/merchant-wordpress/merchant-wordpress.module').then(m => m.MerchantWordpressModule) },
  { path: 'travel', loadChildren: () => import('./components/travel-wordpress/travel-wordpress.module').then(m => m.TravelWordpressModule) },
  { path: 'corporates', loadChildren: () => import('./components/corporates-wordpress/corporates-wordpress.module').then(m => m.CorporatesWordpressModule) },
  { path: 'education', loadChildren: () => import('./components/education-wordpress/education-wordpress.module').then(m => m.EducationWordpressModule) },
  { path: 'film-production', loadChildren: () => import('./components/film-production-wordpress/film-production-wordpress.module').then(m => m.FilmProductionWordpressModule) },
  { path: 'about-us', loadChildren: () => import('./components/about-us-wordpress/about-us-wordpress.module').then(m => m.AboutUsWordpressModule) },
  { path: 'support-page', loadChildren: () => import('./components/support-page-wordpress/support-page-wordpress.module').then(m => m.SupportPageWordpressModule) },
  { path: 'create-order-request/:create_task_id', loadChildren: () => import('./components/admin-order/admin-order.module').then(m => m.AdminOrderModule) },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
