import * as io from 'socket.io-client';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ThemeCommonService } from './common.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { TwilioService } from './twilio.service';

@Injectable()
export class SocketService {
  socket;
  isNotificationCount = 0;
  newBookingUpdate = new Subject<any>();
  reassignBooking = new Subject<any>();
  audio = new Audio();
  constructor(
    public themeCommonService: ThemeCommonService,
    public toastr: ToastrService,
    public twilioService: TwilioService
  ) {
    this.audio.src = "assets/notification_sound/Notification bell.mp3"
    this.audio.load();
    // this.audio.play();
  }

  incrementNotificationCount() {
    this.isNotificationCount += 1;
    this.audio.play();
  }

  socketConnect() {
    this.socket.emit('user-connect', {
      user_type: this.themeCommonService.user_type,
      access_token: localStorage.getItem('tokan')
    }, (element) => {
      console.log('>>>>> IN RESPONSE >>>>>>');
    });
  }
  setupSocketConnection() {
    this.socket = io(environment.BE_URL, {
      reconnectionDelayMax: 10000,
      serveClient: false,
      origins: '*:*',
      transports: ['polling'],
      pingInterval: 10000,
      pingTimeout: 5000,
      cookie: false
    });

    this.socketConnect();

    this.socket.on('connected', (element) => {
      console.log('>>>>> In connected >>>>>', element);
    });

    this.socket.on("disconnect", () => {
      console.log('>>>>> In disconnect >>>>>', this.socket.id, this.socket.connected);
      this.socketConnect();
    });

    setInterval(() => {
      if (this.socket && this.socket.emit) {
        navigator.geolocation.getCurrentPosition(resp => {
          if (this.socket && this.socket.emit)
            this.socket.emit('patient-current-location', {
              user_type: this.themeCommonService.user_type,
              access_token: localStorage.getItem('tokan'),
              routes: {
                latitude: resp.coords.latitude,
                longitude: resp.coords.longitude
              }
            });
          // console.log('>>>> IN  patient-current-location >>>>>', resp.coords);
        }, err => { });
      }
    }, 1000);

    this.socket.on('isUnreadNotifications', (element) => {
      element = JSON.parse(element);
      this.isNotificationCount = element.unread_count || 0;
      console.log('>> isUnreadNotifications >>', element);
    });

    this.socket.on('BOOKING_ASSIGNED', (element) => {
      element = JSON.parse(element);
      console.log('>>>>> BOOKING_ASSIGNED >>>>>', element);
      this.incrementNotificationCount();
      this.toastr.success(element.message);
      this.newBookingUpdate.next(element.extraData);
    });
    this.socket.on('BOOKING_STARTED', (element) => {
      element = JSON.parse(element);
      this.incrementNotificationCount();
      console.log('>>>>> BOOKING_STARTED >>>>>', element);
      this.toastr.success(element.message);
      this.newBookingUpdate.next(element.extraData);
    });
    this.socket.on('AGENT_ARRIVED', (element) => {
      element = JSON.parse(element);
      this.incrementNotificationCount();
      console.log('>>>>> AGENT_ARRIVED >>>>>', element);
      this.toastr.success(element.message);
      this.newBookingUpdate.next(element.extraData);
    });
    this.socket.on('BOOKING_SUCCESSFUL', (element) => {
      element = JSON.parse(element);
      this.incrementNotificationCount();
      console.log('>>>>> BOOKING_SUCCESSFUL >>>>>', element);
      this.toastr.success(element.message);
      this.newBookingUpdate.next(element.extraData);
    });
    this.socket.on('BOOKING_CANCELLED', (element) => {
      element = JSON.parse(element);
      this.incrementNotificationCount();
      console.log('>>>>> BOOKING_CANCELLED >>>>>', element);
      this.toastr.success(element.message);
      this.newBookingUpdate.next(element.extraData);
    });
    this.socket.on('BOOKING_DELAYED', (element) => {
      element = JSON.parse(element);
      this.incrementNotificationCount();
      console.log('>>>>> BOOKING_DELAYED >>>>>', element);
      this.toastr.success(element.message);
      this.newBookingUpdate.next(element.extraData);
    });

    this.socket.on('BOOKING_ON_HOLD', (element) => {
      element = JSON.parse(element);
      this.incrementNotificationCount();
      console.log('>>>>> BOOKING_ON_HOLD >>>>>', element);
      this.toastr.success(element.message);
      this.newBookingUpdate.next(element.extraData);
    });

    this.socket.on('BOOKING_REASSIGNMENT', (element) => {
      element = JSON.parse(element);
      console.log('>>>>> BOOKING_REASSIGNMENT >>>>>', element);
      this.toastr.success(element.message);
      this.reassignBooking.next(element);
      this.newBookingUpdate.next(element.extraData);
    });

    this.socket.on('BOOKING_LATE_PUSH', (element) => {
      element = JSON.parse(element);
      console.log('>>>>> BOOKING_LATE_PUSH >>>>>', element);
      this.toastr.success(element.message);
    });
    this.socket.on('BOOKING_RESCHEDULE', (element) => {
      element = JSON.parse(element);
      console.log('>>>>> BOOKING_RESCHEDULE >>>>>', element);
      this.toastr.success(element.message);
      this.audio.play();
      this.newBookingUpdate.next(element.extraData);
    });
    this.socket.on('BOOKING_RESCHEDULE_EXPIRED', (element) => {
      element = JSON.parse(element);
      console.log('>>>>> BOOKING_RESCHEDULE_EXPIRED >>>>>', element);
      this.toastr.success(element.message);
      this.audio.play();
      this.newBookingUpdate.next(element.extraData);
    });
    this.socket.on('BOOKING_RESCHEDULE_ADMIN', (element) => {
      element = JSON.parse(element);
      this.toastr.success(element.message);
      this.audio.play();
      console.log('>>>>> BOOKING_RESCHEDULE_ADMIN >>>>>', element);
      this.newBookingUpdate.next(element.extraData);
    });

    this.reveiveMessageToJoinCommunication();
  }

  chatJoinSocket(booking_id) {
    if (this.socket)
      this.socket.emit('join_booking_chat', {
        user_type: this.themeCommonService.user_type,
        access_token: localStorage.getItem('tokan'),
        booking_id: booking_id
      }, (element) => {
        console.log('>>>>> IN join_booking_chat RESPONSE >>>>>>', element);
      }, (err) => {
        console.log('>>>>> IN join_booking_chat ERR >>>>>>', err);
      });
  }

  chatLeaveSocket(booking_id) {
    if (this.socket)
      this.socket.emit('leave_booking_chat', {
        user_type: this.themeCommonService.user_type,
        access_token: localStorage.getItem('tokan'),
        booking_id: booking_id
      }, (element) => {
        console.log('>>>>> IN leave_booking_chat RESPONSE >>>>>>', element);
      }, (err) => {
        console.log('>>>>> IN leave_booking_chat ERR >>>>>>', err);
      });
  }

  sendMessageToJoinCommunication(booking_id, message) {
    console.log('sendMessageToJoinCommunication >>>>>>>');
    if (this.socket)
      this.socket.emit('send-comm-msg', {
        user_type: this.themeCommonService.user_type,
        access_token: localStorage.getItem('tokan'),
        booking_id: booking_id,
        is_merchant: 1,
        message: message
      }, (element) => {
        console.log('>>>>> IN sendMessageToJoinCommunication RESPONSE >>>>>>', element);
      }, (err) => {
        console.log('>>>>> IN sendMessageToJoinCommunication ERR >>>>>>', err);
      });
  }

  reveiveMessageToJoinCommunication() {
    console.log('reveiveMessageToJoinCommunication >>>>>>>');
    this.socket.on('get-comm-msg', (element) => {
      this.twilioService.isChatParticipant = false;
      console.log('>>>>> IN reveiveMessageToJoinCommunication RESPONSE >>>>>>', element);
      if (element != 'Chat disconnected')
        this.toastr.success(element);
    }, (err) => {
      console.log('>>>>> IN reveiveMessageToJoinCommunication ERR >>>>>>', err);
    });

    this.socket.on('SEND_SMS', (element) => {
      this.twilioService.isChatParticipant = false;
      element = JSON.parse(element);
      console.log('>>>>> IN SEND_SMS RESPONSE >>>>>>', element);
      if (element.message != 'Chat disconnected')
        this.toastr.success(element.message);
    }, (err) => {
      console.log('>>>>> IN SEND_SMS ERR >>>>>>', err);
    });
  }
}
