<router-outlet></router-outlet>
<!-- Modal -->
<div class="modal fade" id="acceptMerchant" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-weight-semi-bold line-height-125 text-24 text-dark-green">Accept Merchant
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
              fill="#323232" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              {{reassignMessage}}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center border-0">
        <button type="submit" class="btn btn-fixed" (click)="assignMerchant(1)">Accept</button>
        <button type="submit" class="btn btn-outline" (click)="assignMerchant(2)">Reject</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="alertMiss" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body pl-5 pr-5 pb-5 pt-3 text-center">
        <img src="assets/back_soon.jpeg" height="200px" alt="">
        <div>
          To our valued testing customers, Your Health Pro will be closed due to unforseen staffing shortages. Valley
          Immediate Care will be doing testing, however Hawaii travelers will need to find other resources. Please check
          back periodically we will announce a reopen date soon. Again, we apologize for the inconvenience and thank you
          for your understanding.
        </div>
        <br>
        Your Health Pro Management
      </div>
    </div>
  </div>
</div>
