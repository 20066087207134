import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { EmailValidator } from './validators/email.validator';

import { AppComponent } from './app.component';

import { CountryCodeService } from './services/countryCode.service';
import { HttpService } from './services/http.service';
import { ThemeCommonService } from './services/common.service';
import { BaThemeSpinner } from './services/leader.service';
import { LocationService } from './services/location.service';
import { StatusService } from './services/status.service';
import { SocketService } from './services/socket.service';
import { TwilioService } from './services/twilio.service';
import { StrictNumberOnlyDirective } from './directives/strictNumbersOnly';

@NgModule({
  declarations: [
    AppComponent,
    StrictNumberOnlyDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    })
  ],
  providers: [
    CountryCodeService,
    HttpService,
    ThemeCommonService,
    EmailValidator,
    BaThemeSpinner,
    LocationService,
    StatusService,
    SocketService,
    TwilioService
  ],
  bootstrap: [AppComponent],
  exports:[]
})
export class AppModule { }
