import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/leader.service";
export class AdminGuard {
    constructor(router, baThemeSpinner) {
        this.router = router;
        this.baThemeSpinner = baThemeSpinner;
        // console.log("Auth guard public pages service constructor called");
    }
    canActivate(next, state) {
        let url = state.url;
        if ((url == '/login') || (url == '/register') || (url == '/forgot-password') || (url == '/new-section')) {
            if (localStorage.getItem('tokan')) {
                this.router.navigate(['']);
                this.baThemeSpinner.hide();
                // return true;
            }
        }
        return true;
    }
}
AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.BaThemeSpinner)); }, token: AdminGuard, providedIn: "root" });
