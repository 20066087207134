import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from "rxjs/operators";
import { of } from 'rxjs/internal/observable/of';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ThemeCommonService } from './common.service';


@Injectable()
export class HttpService {

  constructor(
    public http: HttpClient,
    public toastr: ToastrService,
    public router: Router,
    public themeCommonService: ThemeCommonService
  ) { }
  postData(url, payload) {

    let headers = new HttpHeaders();
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.BE_URL + url, payload)
      .pipe(map((res: any) => {
        return this.handleResponse(res);
      }),
        catchError((error) => {
          return of(error.error);
        })
      );

  }

  patchData(url, payload) {

    let headers = new HttpHeaders();
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.patch(environment.BE_URL + url, payload)
      .pipe(map((res: any) => {
        return this.handleResponse(res);
      }),
        catchError((error) => {
          return of(error.error);
        })
      );

  }

  getGoogleData(url) {
    let headers = new HttpHeaders();

    return this.http.get(url, { headers: headers })
      .pipe(map((res: any) => {
        return this.handleResponse(res);
      }),
        catchError((error) => {
          return of(error.error);
        })
      );

  }

  getData(url) {
    let headers = new HttpHeaders();

    headers = new HttpHeaders({ 'content-language': 'en' });

    return this.http.get(environment.BE_URL + url, { headers: headers })
      .pipe(map((res: any) => {
        return this.handleResponse(res);
      }),
        catchError((error) => {
          return of(error.error);
        })
      );
  }


  resetPutData(url, payload) {
    let headers = new HttpHeaders({
      'content-language': payload.language
    });
    if (localStorage.getItem('accessToken'))
      headers = new HttpHeaders({
        'passwordResetToken': payload.token
      });
    delete payload.language;
    delete payload.token;

    return this.http.put(environment.BE_URL + url, payload, { headers: headers })
      .pipe(map((res: any) => {
        return this.handleResponse(res);
      }),
        catchError((error) => {
          return of(error.error);
        })
      );
  }

  putData(url, payload) {
    let headers = new HttpHeaders({
      'content-language': 'en'
    });
    if (localStorage.getItem('accessToken'))
      headers = new HttpHeaders({
        'authorization': 'Bearer ' + localStorage.getItem('accessToken')
      });

    return this.http.put(environment.BE_URL + url, payload, { headers: headers })
      .pipe(map((res: any) => {
        return this.handleResponse(res);
      }),
        catchError((error) => {
          return of(error.error);
        })
      );
  }

  deleteData(url) {

    let headers = new HttpHeaders();

    headers = new HttpHeaders({ 'content-language': 'en' });
    if (localStorage.getItem('accessToken'))
      headers = new HttpHeaders({
        'content-language': 'en',
        'authorization': 'Bearer ' + localStorage.getItem('accessToken')
      });
    return this.http.delete(environment.BE_URL + url, { headers: headers })
      .pipe(map((res: any) => {
        return this.handleResponse(res);
      }),
        catchError((error) => {
          return of(error.error);
        })
      );

  }

  handleResponse(res) {
    if (res.status === 101 || res.status === 401 || res.message == "\"access_token\" must be a string") {
      this.logout('Please login again');
      return {};
    } else if (res.status === 200) {
      return res;
    } else {
      // if (res.message == 'WRONG OTP')
      //   this.toastr.error('Link Expire');
      // else
      this.toastr.error(res.message);
    }
    return {};
  }

  lastErrorMessage = '';
  logout(message) {
    if (this.lastErrorMessage != 'User not active. Please contact Admin.')
      this.toastr.error(message);

    if (message)
      this.lastErrorMessage = message || '';

    this.themeCommonService.accessToPrivatePages = false;
    localStorage.removeItem('tokan');
    localStorage.removeItem('searchLat');
    localStorage.removeItem('searchLng');
    localStorage.removeItem('_id');
    localStorage.removeItem('setUrl');
    localStorage.removeItem('email');
    localStorage.removeItem('user_data');

    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 500)
  }
}
