import { Injectable } from '@angular/core';
import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import * as moment from 'moment';
declare var google;
declare var FB: any;
@Injectable()
export class ThemeCommonService {

  public selectedItem = '';
  public accessToPrivatePages = false;
  public sideBarToggle = false;
  public showSideCart = false;
  public user_type = 3;
  public user_data = {};
  public selectedBookingId = '';

  public profile_side_bar = new Subject<any>();

  accessTokan;

  changeAvailabilityBroadCast = new Subject<any>();

  paymentObject;

  editAddressObject = {};

  selectedSlotDetails = {};

  updateSelectedSlotDetails = new Subject<any>();

  ratingBookingId;

  isSearch = false;

  is_custom_booking = 0;

  pendingInfoDetails = new Subject<any>();

  isMobile = false;
  iscall: boolean = false;

  constructor() {

    if (window.screen.width < 426) {
      this.isMobile = true;
    }

    if (localStorage.getItem('user_data'))
      this.user_data = JSON.parse(localStorage.getItem('user_data'))

    if (localStorage.getItem('tokan')) {
      this.accessTokan = localStorage.getItem('tokan');
      this.accessToPrivatePages = true;
    }

    if (location.hash.includes('booking-details')) {
      this.selectedBookingId = location.hash.split('/')[location.hash.split('/').length - 1]
    }
    if (location.hash.includes('booking/communication') && JSON.parse(localStorage.getItem('chat_type')) == 1) {
      this.iscall = true;
    }
  }

  setAccessToPrivatePages(data) {
    this.accessToPrivatePages = data;
  }

  toggleSidebar(data) {
    this.sideBarToggle = data;
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  numbersOnly(evt) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changeSideCartStatus(status) {
    this.showSideCart = status;
  }

  facebookLoginInit() {
    (<any>window).fbAsyncInit = () => {
      FB.init({
        appId: '1616380785193611',
        // autoLogAppEvents : true,
        xfbml: false,
        version: 'v3.2'
      });
      FB.AppEvents.logPageView();
    };
    this.intializeScript(
      'facebook-jssdk',
      '//connect.facebook.net/en_US/sdk.js'
    );
  }

  intializeScript(scriptId, scriptName) {
    let js;
    const fjs = document.getElementsByTagName('script')[0];
    if (document.getElementById(scriptId)) {
      return;
    }
    js = document.createElement('script');
    js.id = scriptId;
    js.async = true;
    js.src = scriptName;

    fjs.parentNode.insertBefore(js, fjs);
  }

  bookingFormatTime(time) {
    return moment(time).format('MM/DD/YYYY (hh:mm A)');
  }
  formatDate(time) {
    return moment(time).format('MM/DD/YYYY');
  }
  formatTime(time) {
    return moment(time).format('hh:mm A');
  }
  convertUTCintoLocal(date, time, isFormat, timezone?) {
    if (!date || !time)
      return '--';

    timezone = timezone || moment.tz.guess();

    let hour = time.split(':')[0];
    let minute = time.split(':')[1];
    let utcDateTime = moment(date).utc().set({ "hour": hour, "minute": minute });

    let localDateTime;
    localDateTime = moment.utc(utcDateTime).tz(timezone);


    if (isFormat)
      localDateTime = localDateTime.format('hh:mm a');

    return localDateTime;
  }

  getOneDayTimeForPhle(date) {
    let timeArray = [];

    for (let index = 0; index < 48; index++) {
      if (moment(moment(date).startOf('day').add(index * 30, 'minutes')).isSameOrAfter(moment()))
        timeArray.push({
          utcTime: moment(date).startOf('day').utc().add(index * 30, 'minutes'),
          localTime: moment(date).startOf('day').add(index * 30, 'minutes').format('hh:mm a'),
          utcDate: moment(date).startOf('day').utc().add(index * 30, 'minutes').format('YYYY-MM-DD'),
          localDate: moment(date).startOf('day').add(index * 30, 'minutes').format('YYYY-MM-DD')
        });
    }

    return timeArray || [];
  }

  getOneDayTimeForHealthCare(date) {
    let timeArray = [];
    for (let index = 0; index < 48; index++) {
      if (moment(moment(date).startOf('day').add(index * 30, 'minutes')).isSameOrAfter(moment()))
        timeArray.push({
          utcTime: moment(date).startOf('day').utc().add(index * 30, 'minutes').format('HH:mm'),
          localTime: moment(date).startOf('day').add(index * 30, 'minutes').format('hh:mm a'),
          utcDate: moment(date).startOf('day').utc().add(index * 30, 'minutes').format('YYYY-MM-DD'),
          localDate: moment(date).startOf('day').add(index * 30, 'minutes').format('YYYY-MM-DD')
        });
    }

    return timeArray || [];
  }

  calculateAgeFromDOB(dob) {
    return moment().diff(moment(dob, 'YYYY-MM-DD'), 'years');
  }
}
