import { Injectable } from '@angular/core';

@Injectable()
export class StatusService {

  checkStatus(type) {
    switch (type) {
      case 1:
        return 'Pending';
      case 2:
        return 'Accepted';
      case 3:
        return 'In Progress';
      case 4:
        return 'Completed';
      case 5:
        return 'Rejected';
      case 6:
        return 'Delayed';
      case 7:
        return 'WAITING_QC';
      case 8:
        return 'PAID';
      case 9:
        return 'Hold';
      default:
        return 'Delayed';
    }
  }
  checkColor(type) {
    switch (type) {
      case 1:
        return '#f5bd79';
      case 2:
        return '#003A68';
      case 3:
        return '#003A68';
      case 4:
        return '#cee6ef';
      case 5:
        return '#cee6ef';
      case 6:
        return '#003A68';
      case 7:
        return '#003A68';
      case 8:
        return '#003A68';
      case 9:
        return '#003A68';
      default:
        return '#cee6ef';
    }
  }

  changeStatus(type) {
    switch (type) {
      case 1:
        return 'ACCEPTED';
      case 11://Custom Status
        return 'REJECTED';
      case 2:
        return 'STARTED';
      case 3:
        return 'SUCCESSFUL';
      case 33://Custom Status
        return 'REJECT';
      case 4:
        return 'WAITING_QC';
      case 5:
        return 'FAILED';
      case 7:
        return 'WAITING_QC';
      case 8:
        return 'PAID';
      case 9:
        return 'HOLD';
      case 10:
        return 'CANCELED_BY_CUSTOMER';
      default:
        return 'SUCCESSFUL';
    }
  }

  changeStatusLabel(type) {
    switch (type) {
      case 1:
        return 'Accept';
      case 2:
        return 'Start';
      case 3:
        return 'Complete';
      case 4:
        return 'Complete';
      case 5:
        return 'Reject';
      case 6:
        return 'Delayed';
      case 7:
        return 'WAITING QC';
      case 8:
        return 'PAID';
      case 9:
        return 'Hold';
      default:
        return 'Complete';
    }
  }
}
