import { Injectable, EventEmitter, ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observer } from 'rxjs';
import { Router } from '@angular/router';
// import { BaCustomPreLoader } from './baCustomPreloader.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { connect, createLocalTracks, createLocalVideoTrack } from 'twilio-video';
import { BaThemeSpinner } from './leader.service';
import { ToastrService } from 'ngx-toastr';

declare var Twilio;
@Injectable()
export class TwilioService {

  remoteVideo: ElementRef;
  localVideo: ElementRef;
  previewing: boolean;
  msgSubject = new BehaviorSubject(null);
  roomObj: any;

  roomParticipants;
  type = 1;
  public isCameraDisabled = false;
  public chatClient;
  public currentChannel;
  public chatConnectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  public chatDisconnectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  public messages: EventEmitter<any> = new EventEmitter<any>();
  // public channel;
  private renderer: Renderer2;
  participantName = '';
  constructor(
    private http: HttpClient,
    private router: Router,
    private rendererFactory: RendererFactory2,
    private baCustomPreLoader: BaThemeSpinner,
    public toastr: ToastrService) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.chatClient = Twilio.Chat.Client;
    // this.currentChannel
  }

  channelUniqueName = '';
  booking_id =  '';
  connectChat(token) {
    this.baCustomPreLoader.hide();
    Twilio.Chat.Client.create(token).then(client => {
      this.chatClient = client;
      // console.log('client >>>>>', client, client.user);
      this.currentUserName = client.user.friendlyName || client.user.identity;
      client.createChannel({
        friendlyName: this.channelUniqueName,
        uniqueName: this.booking_id,
        isPrivate: false
      }).then(object => {
        this.currentChannel = object;
        // this.currentChannel.join();

        if (this.currentChannel.status !== 'joined') {
          client.on('channelJoined', () => {
            this.initChannel();
          });
          this.currentChannel.join();
        } else {
          this.initChannel();
        }


        console.log('object >>>>>', this.currentChannel);
      }, (err) => {
        console.log('object >>>>>', this.currentChannel, err);

        client.getChannelByUniqueName(this.booking_id).then(object => {
          this.currentChannel = object;
          console.log('object >>>>>', this.currentChannel);
          if (this.currentChannel.status !== 'joined') {
            client.on('channelJoined', () => {
              this.initChannel();
            });
            this.currentChannel.join();
          } else {
            this.initChannel();
          }
        });


      });

      // this.chatConnectedEmitter.emit(client);
    }).catch((err: any) => {
      this.chatDisconnectedEmitter.emit(true);
      if (err.message.indexOf('token is expired')) {
        localStorage.removeItem('twackToken');
        this.router.navigate(['/']);
      }
    });
  }

  // getPublicChannels() {
  //   return this.chatClient.getPublicChannelDescriptors();
  // }

  // getChannel(sid: string): Promise<Channel> {
  //   return this.chatClient.getChannelBySid(sid);
  // }
  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
  createChannel(friendlyName: string, isPrivate: boolean = true) {
    return this.chatClient.createChannel({ friendlyName: friendlyName, isPrivate: isPrivate, uniqueName: this.guid() });
  }

  currentUserName;

  getChannel(sid: string): Promise<any> {
    return this.chatClient.getChannelBySid(sid);
  }

  getAllChannels(channel) {
    console.log('>>>>>. currentChannel >>>>>>>>', channel, channel.sid);
    this.getChannel(channel.sid).then(ch => {
      this.currentChannel = ch;
      this.currentChannel.join()
        .then(r => {
          this.initChannel();
        })
        .catch(e => {
          if (e.message.indexOf('already exists') > 0) {
            this.initChannel();
          }
        });
    })
  }

  isChatParticipant = false;
  // messages = [];
  initChannel() {
    this.baCustomPreLoader.hide();

    // ******** GET PREVIOUS CHAT ********
    this.currentChannel.getMessages().then((object) => {
      object.items.forEach(m => {
        // console.log('m >>>>>>', m)
        let name;
        if (this.currentUserName == m.author) {
          name = 'You'
        } else {
          name = this.participantName
        }
        this.messages.emit({
          name: name,
          message: m.body,
          time: moment(m.dateUpdated).format('hh:mm a'),// new Date(m.dateUpdated)
        });
      });
    })
    // this.messages = (this.currentChannel.getMessages()).items;

    console.log('>>>> JOINED INIT');
    // this.chatClient.channel.members.create({ identity: localStorage.getItem('bookingFrom') }).then(member => console.log('member.sid >>>>>>>>>', member.sid));
    // this.currentChannel.getMessages().then((messages) => {
    //   const totalMessages = messages.items.length;
    //   for (let i = 0; i < totalMessages; i++) {
    //     const message = messages.items[i];
    //     console.log('Author:' + message.author);
    //     let name;
    //     if (this.currentUserName == message.author) {
    //       name = 'You'
    //     } else {
    //       if (localStorage.getItem('bookingFrom') == 'doctor')
    //         name = JSON.parse(localStorage.getItem('bookingDetails')).patientName;
    //       else
    //         name = JSON.parse(localStorage.getItem('bookingDetails')).doctorName;
    //     }
    //     this.messages.emit({
    //       name: name,
    //       message: message.body,
    //       time: moment(message.dateUpdated).format('hh:mm a'),// new Date(m.dateUpdated)
    //     });
    //   }
    //   console.log('Total Messages:' + totalMessages);
    // });
    // this.typeObservable = Observable.fromEvent(this.chatElement.nativeElement, 'keyup').debounceTime(100).subscribe(() => {
    //   this.typing();
    // });
    this.currentChannel.on('messageAdded', (m) => {
      console.log('>>>>> MESSAGE EMIT >>>>>>', m);
      let name;
      if (this.currentUserName == m.author) {
        name = 'You'
      } else {
        this.isChatParticipant = true;
        name = this.participantName;
      }
      this.messages.emit({
        name: name,
        message: m.body,
        time: moment(m.dateUpdated).format('hh:mm a'),// new Date(m.dateUpdated)
      });
      // this.messages.emit({
      // this.chatClient.channel.members.create({ identity: localStorage.getItem('bookingFrom') }).then(member => console.log('member.sid >>>>>>>>>', member.sid));
      // this.currentChannel.members.create({ identity: localStorage.getItem('bookingFrom') }).then(member => console.log('member.sid >>>>>>>>>', member.sid));
      //   name: m.author,
      //   message: m.body,
      //   time: new Date(m.dateUpdated)
      // });
      console.log('m.author >>>>>>>>>', m.author, m.body, m.identity);
      console.log('>>>> messages in messageAdded >>>>', m)
    });
    // this.currentChannel.on('typingStarted', (m) => {
    //   this.membersTyping.push(m);
    // });
    // this.currentChannel.on('typingEnded', (m) => {
    //   const mIdx = this.membersTyping.findIndex(mem => mem.identity === m.identity);
    //   this.membersTyping = this.membersTyping.splice(mIdx, 0);
    // });
  }

  getPublicChannels() {
    return this.chatClient.getPublicChannelDescriptors();
  }

  microphone = true;

  mute() {
    console.log('>>>>>>> MUTE >>>>>>', this.roomParticipants);
    console.log('>>>>>>> MUTE ARRAY >>>>>>', Array.from(this.roomParticipants.values()));
    this.roomObj.localParticipant.audioTracks.forEach(function (
      audioTrack
    ) {
      console.log("muting this users audio", audioTrack);
      audioTrack.track.disable();
    });

    // var tracks = Array.from(participant.tracks.values());
    // this.roomParticipants.forEach(participant => {
    //   console.log('>>> participant >>>>', participant);
    //   // participant.tracks.forEach(part => {
    //   // console.log('>>> part >>>>', part);
    //   // participant.on('subscribed', track => {
    //   //   console.log('>>> track >>>>', track);
    //   participant.audioTracks.forEach(function (audioTrack) {
    //     console.log('+++++ audioTrack ' + audioTrack + ' +++++', audioTrack.track);
    //     // audioTrack.disable();
    //     audioTrack.track.disable();
    //   });
    //   // });
    //   // });
    // })
    console.log('>>> end >>>>');


    // this.roomParticipants.audioTracks.forEach(function (audioTrack) {
    //   console.log('+++++ audioTrack ' + audioTrack + ' +++++');
    //   audioTrack.disable();
    // });
    this.microphone = false;
  }


  unmute() {
    console.log('>>>>>>> UNMUTE >>>>>>', this.roomParticipants);
    this.roomObj.localParticipant.audioTracks.forEach(function (
      audioTrack,
      key,
      map
    ) {
      console.log("muting this users audio", audioTrack);
      audioTrack.track.enable();
    });
    this.microphone = true;
  }


  connectToRoom(accessToken: string, options): void {
    console.log('Connect To Room', accessToken, options);
    if (this.type == 2)
      this.connectChat(accessToken);

    if (this.type == 1)
      connect(accessToken, options).then(room => {
        // this.connectChat(accessToken);
        console.log('Room connected', room);
        this.isCameraDisabled = true;
        this.roomObj = room;

        if (!this.previewing && options['video'] && (this.type == 1)) {
          this.startLocalVideo();
          this.previewing = true;
        }

        this.roomParticipants = room.participants;
        console.log('>>>>>> roomParticipants >>>>>', this.roomParticipants);
        room.participants.forEach(participant => {
          // this.msgSubject.next("Already in Room: '" + participant.identity + "'");
          this.msgSubject.next({ participant: participant.identity, type: 'connected' });

          console.log('>>>>> Participant enter Connected', participant);
          // console.log("Already in Room: '" + participant.identity + "'");
          if (this.type == 1)
            this.attachParticipantTracks(participant);
        });

        room.on('participantDisconnected', (participant) => {
          console.log('>>>>> Participant disConnected', participant);
          this.msgSubject.next({ participant: participant.identity, type: 'disconnected' });
          // console.log("Participant '" + participant.identity + "' left the room");
          if (this.type == 1)
            this.detachParticipantTracks(participant);
        });

        room.on('participantConnected', (participant) => {
          this.msgSubject.next({ participant: participant.identity, type: 'connected' });
          // console.log('>>>>> Participant Connected', participant);
          if (this.type == 1) {
            this.roomParticipants = room.participants;
            this.attachParticipantTracks(participant);
            // participant.tracks.forEach(track => {
            //   this.remoteVideo.nativeElement.appendChild(track.attach());
            // });

            participant.on('trackPublished', track => {
              // console.log('track added')
              if (this.type == 1) {
                const element = track.attach();
                this.renderer.data.id = track.sid;
                // this.renderer.setStyle(element, 'width', '65%');
                this.renderer.setStyle(element, 'height', '100%');
                this.renderer.setStyle(element, 'max-width', '100%');
                // this.renderer.setStyle(element, 'margin-left', '2.5%');
                this.renderer.appendChild(this.remoteVideo.nativeElement, element);


                // this.remoteVideo.nativeElement.appendChild(track.attach());

                this.attachVideoClass();
                // document.getElementById('remote-media-div').appendChild(track.attach());
              }
              this.baCustomPreLoader.hide();
            });
          }
        });

        // When a Participant adds a Track, attach it to the DOM.
        room.on('trackPublished', (track, participant) => {
          // console.log('>>>> 56');
          console.log(participant.identity + " added track: " + track.kind);
          if (this.type == 1)
            this.attachTracks([track]);
        });

        // When a Participant removes a Track, detach it from the DOM.
        // room.on('trackRemoved', (track, participant) => {
        //   console.log('>>>> 56');
        //   console.log(participant.identity + " removed track: " + track.kind);
        //   this.detachTracks([track]);
        // });

        room.once('disconnected', room => {
          // console.log('Disconnect', room);
          // this.msgSubject.next('You left the Room:' + room.name);
          room.localParticipant.tracks.forEach(track => {
            // console.log('>>>>> TRACK >>>>>>', track);
            track.track.stop();
            const attachedElements = track.track.detach();

            // console.log("unsubscribed from: " + track.track, attachedElements)
            attachedElements.forEach(element => element.remove());
            room.localParticipant.videoTracks.forEach(video => {
              // console.log('>>>>> VIDEO >>>', video);
              const trackConst = [video][0].track;
              trackConst.stop();  // <- error

              trackConst.detach().forEach(element => element.remove());

              room.localParticipant.unpublishTrack(trackConst);
            });

            this.previewing = false;


            // let element = this.remoteVideo.nativeElement;
            // while (element.firstChild) {
            //   element.removeChild(element.firstChild);
            // }
            // let localElement = this.localVideo.nativeElement;
            // while (localElement.firstChild) {
            //   localElement.removeChild(localElement.firstChild);
            // }

            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000)
          });
          setTimeout(() => {
            this.router.navigate(['bookings/allBookings']);
          }, 1000)
        });
      }, (error) => {
        console.log('>>> IN VIDEO CONNECT ERROR >>>', error);
        // alert(error.message);
        console.log(error);
        alert('Please try again after sometime.');
      });
  }

  attachParticipantTracks(participant): void {
    console.log('>>>> attachParticipantTracks >>>>', participant);
    // var tracks = Array.from(participant.tracks.values());
    participant.tracks.forEach(part => {
      this.trackPublished(part);
    });
  }

  trackPublished(publication) {
    console.log(' >>>>>>>>> publication >>>>>>>>>', publication);
    if (publication.isSubscribed)
      this.attachTracks(publication.track);

    if (!publication.isSubscribed)
      publication.on('subscribed', track => {
        this.attachTracks(track);
      });
    // publication.on('unsubscribed', detachTrack);
  }

  attachTracks(tracks) {
    console.log('>>>> attachTracks >>>>', tracks);
    // tracks.forEach(track => {
    // console.log('+++++ trck >>>>>', track);
    // console.log('+++++ trck', track['RemoteVideoTrackPublication']);
    // if (tracks.dimensions && tracks.dimensions.width && tracks.dimensions.height) {
    if (this.type == 1) {
      const element = tracks.attach();
      this.renderer.data.id = tracks.sid;
      // this.renderer.setStyle(element, 'width', '65%');
      this.renderer.setStyle(element, 'height', '100%');
      this.renderer.setStyle(element, 'max-width', '100%');
      // this.renderer.setStyle(element, 'margin-left', '2.5%');
      this.renderer.appendChild(this.remoteVideo.nativeElement, element);
      // this.remoteVideo.nativeElement.appendChild(tracks.attach());
      this.attachVideoClass();
    }
    this.baCustomPreLoader.hide();
    // }
    // });
  }

  startLocalVideo(): void {
    console.log('>>>> startLocalVideo >>>>');
    this.roomObj.localParticipant.videoTracks.forEach(publication => {
      if (this.type == 1) {
        const element = publication.track.attach();
        this.renderer.data.id = publication.track.sid;
        this.renderer.setStyle(element, 'width', '100%');
        // this.renderer.setStyle(element, 'margin-left', '2.5%');
        this.renderer.appendChild(this.localVideo.nativeElement, element);
      }


      // const localMediaContainer = document.getElementById('local-media');
      // localMediaContainer.appendChild(publication.track.attach());
    })

    // createLocalVideoTrack({
    //   video: { width: 1280, height: 720 },
    // }).then(track => {
    //   console.log('>>>> 99 >>>>', track);
    //   if (this.localVideo) {

    //     const element = track.attach();
    //     this.renderer.data.id = track.sid;
    //     this.renderer.setStyle(element, 'width', '25%');
    //     // this.renderer.setStyle(element, 'margin-left', '2.5%');
    //     this.renderer.appendChild(this.localVideo.nativeElement, element);


    //     // this.localVideo.nativeElement.appendChild(track.attach());
    //     this.attachVideoClass();

    //   }
    // });
  }

  // localPreview(): void {
  //   console.log('>>>> localPreview >>>>');
  //   createLocalVideoTrack().then(track => {
  //     console.log('>>>> 99 >>>>', track);
  //     this.localVideo.nativeElement.appendChild(track.attach());
  //     this.attachVideoClass();
  //   });
  // }

  detachParticipantTracks(participant) {
    console.log('>>>> detachParticipantTracks >>>>');
    // var tracks = Array.from(participant.tracks.values());
    this.detachTracks(participant);
  }

  detachTracks(tracks): void {
    console.log('>>>> 22 >>>>', tracks);
    tracks.tracks.forEach(track => {
      console.log('>>>>> 11 >>>', track);
      // track.on('subscribed', singleTrack => {
      //   console.log('>>>> track track >>>>', singleTrack);
      //   singleTrack.track.detach();
      //   // this.remoteVideo.nativeElement.remove();
      if (this.type == 1) {
        let element = this.remoteVideo.nativeElement;
        console.log('>>> element >>>', element);
        while (element.firstChild) {
          element.removeChild(element.firstChild);
          this.toastr.error('Merchant disconnected.');
        }
      }
      // });
    });
  }

  attachVideoClass() {
    let remote = document.getElementById('remote');
    // for (let index = 0; index < remote.getElementsByTagName("video").length; index++) {
    //   if (remote && remote.getElementsByTagName("video")[index])
    //     remote.getElementsByTagName("video")[index].className += " w-75";
    // }
  }
}
