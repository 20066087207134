import { FormControl, FormGroup } from '@angular/forms';
export class EmailValidator {

  static email(control: FormControl): { [s: string]: boolean } {

    let EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (control.value && control.value !== '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { invalid: true };
    }
  }
}

export class EqualEmailsValidator {

  public static validate(emailKey: string, confirmEmailKey: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let email = group.controls[emailKey];
      let confirmEmail = group.controls[confirmEmailKey];

      if (email.value !== confirmEmail.value) {
        return {
          mismatchedEmails: true
        };
      }
    };
  }
}
export class EmailPhoneValidator {

  static emailPhone(control: FormControl): { [s: string]: boolean } {

    let EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let mobNumberPattern = /^((\\+91-?)|0)?[0-9]{10}$/;
    if (control.value && control.value !== '' && (control.value.length <= 5 || (!EMAIL_REGEXP.test(control.value) && !mobNumberPattern.test(control.value)))) {
      return { invalid: true };
    }
  }
}
