import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaThemeSpinner } from '../services/leader.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    public baThemeSpinner: BaThemeSpinner) {
    // console.log("Auth guard public pages service constructor called");
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let url: string = state.url;

    if ((url == '/login') || (url == '/register') || (url == '/forgot-password') || (url == '/new-section')) {
      if (localStorage.getItem('tokan')) {
        this.router.navigate(['']);
        this.baThemeSpinner.hide();
        // return true;
      }
    }
    return true;
  }
}
